import { useApolloClient } from "@apollo/client";
import { useContext, useState } from "react";
import { auth } from "../config/firebase";
import { GET_CUSTOMERS } from "../graphql/queries";
import Connection from "../interfaces/graphql/Connection";
import Customer from "../interfaces/graphql/Customer";
import { UserContext } from "../providers/UserProvider";
import CustomerRow from "./CustomerRow";
import Spinner from "./Spinner";

interface CustomersListInterface {
  customerConnection: Connection<Customer>;
}

export default function CustomersList({
  customerConnection,
}: CustomersListInterface) {
  const { shopId } = useContext(UserContext);
  const [edges, setEdges] = useState(customerConnection.edges);
  const [after, setAfter] = useState(
    customerConnection.edges[customerConnection.edges.length - 1]?.cursor
  );
  const [hasNextPage, setHasNextPage] = useState(
    customerConnection.pageInfo.hasNextPage
  );
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();

  const loadMore = async () => {
    setLoading(true);
    const tokenId = await auth.currentUser.getIdToken();
    const result = await apolloClient.query({
      query: GET_CUSTOMERS,
      variables: {
        shopId,
        first: 10,
        after,
      },
      context: {
        headers: {
          authorization: tokenId ? `Bearer ${tokenId}` : "",
        },
      },
    });
    setHasNextPage(result.data.orders.pageInfo.hasNextPage);
    setAfter(
      result.data.orders.edges[result.data.orders.edges.length - 1].cursor
    );
    setEdges([...edges, ...result.data.orders.edges]);
    setLoading(false);
  };

  var listFooter = null;
  if (hasNextPage) {
    listFooter = (
      <button
        className="py-2 px-6 text-center bg-transparent hover:bg-indigo-500 text-indigo-700 font-semibold hover:text-white border border-indigo-500 hover:border-transparent rounded"
        onClick={() => loadMore()}
      >
        See more
      </button>
    );
  }

  if (loading) {
    listFooter = (
      <div className="flex flex-wrap content-center">
        <Spinner size="h-6 w-6" />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Username
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Phone Number
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Followers
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {edges.map((edge) => (
                  <CustomerRow key={edge.node.id} customer={edge.node} />
                ))}
              </tbody>
            </table>
            {listFooter && (
              <div className="my-4 flex justify-center">{listFooter}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
