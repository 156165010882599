import Order from "../interfaces/graphql/Order";
import { useForm, SubmitHandler } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { POST_REFUND } from "../graphql/mutations";
import { auth } from "../config/firebase";
import { useContext, useState } from "react";
import { UserContext } from "../providers/UserProvider";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Spinner from "./Spinner";
import { timeDifferenceForDate } from "../utils/time";

type Inputs = {
  refundAmount: string;
};

interface OrderRowInterface {
  order: Order;
}

export default function OrderRow({ order }: OrderRowInterface) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const { shopId } = useContext(UserContext);
  const [postRefund] = useMutation(POST_REFUND);

  const [isRefundButtonEnabled, setIsRefundButtonEnabled] = useState<boolean>(
    order.state !== "POST_PENDING"
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refundError, setRefundError] = useState<string>(null);
  const [state, setState] = useState<string>(order.state);
  const [refundAmount, setRefundAmount] = useState<number>(order.refundAmount);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    setIsLoading(true);
    setIsRefundButtonEnabled(false);
    const tokenId = await auth.currentUser.getIdToken();
    const amount: number = Math.round(parseFloat(data.refundAmount) * 100);
    console.log("Refund amount", amount);
    try {
      const result = await postRefund({
        variables: {
          shopId,
          orderId: order.id,
          amount: amount,
        },
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
      if (result.data.postRefund) {
        reset();
        setState("REFUNDED");
        setRefundAmount(amount + order.refundAmount);
      } else {
        setRefundError("Failed to send the refund. Please try again later.");
      }
      console.log(result);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      setIsRefundButtonEnabled(true);
    }
  };

  var orderState = null;
  switch (state) {
    case "USER_POSTED":
      orderState = (
        <div className="flex flex-col">
          <div>
            <span className="px-2 mb-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              {order.discountCode ? "Discount applied" : "Mentioned"}
            </span>
          </div>
          {refundAmount > 0 && (
            <div className="text-sm">Reward: ${refundAmount / 100}</div>
          )}
          <Link
            className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
            to={`/b/mentions?orderId=${order.id}`}
            rel="noreferrer"
          >
            View Mentions
          </Link>
        </div>
      );
      break;
    case "REFUNDED":
      orderState = (
        <div className="flex flex-col">
          <div>
            <span className="px-2 mb-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              Refunded
            </span>
          </div>
          <Link
            className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
            to={`/b/mentions?orderId=${order.id}`}
            rel="noreferrer"
          >
            View Mentions
          </Link>
        </div>
      );
      break;
    default:
      orderState = (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          Pending post
        </span>
      );
      break;
  }

  var orderRefund = null;
  switch (state) {
    case "REFUNDED":
      orderRefund = (
        <div className="flex">
          <div className="text-sm">Reward: ${refundAmount / 100}</div>
        </div>
      );
      break;
    default:
      orderRefund = (
        <>
          <form
            className="flex flex-col items-start"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                disabled={!isRefundButtonEnabled}
                type="number"
                step="0.01"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 py-1 sm:text-sm bg-gray-100 border-gray-300 rounded-md"
                placeholder="0.00"
                {...register("refundAmount", { required: true })}
                aria-describedby="refund-currency"
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm" id="refund-currency">
                  USD
                </span>
              </div>
            </div>
            <div className="flex">
              {isRefundButtonEnabled ? (
                <button
                  className="has-tooltip p-1 mt-2 text-sm text-center bg-transparent hover:bg-indigo-500 text-indigo-700 font-semibold hover:text-white border border-indigo-500 hover:border-transparent rounded disabled:opacity-50 disabled:bg-indigo-600 disabled:text-white"
                  type="submit"
                >
                  Send Refund
                </button>
              ) : (
                <Tooltip
                  arrow={true}
                  placement="left"
                  title={
                    <span className="text-sm">
                      Refunding is enabled once the customer mentions you.
                    </span>
                  }
                >
                  <a
                    className="p-1 disabled mt-2 text-sm text-center bg-transparent font-semibold border border-indigo-500 border-transparent rounded opacity-50 bg-indigo-600 text-white"
                    type="submit"
                    href="#"
                  >
                    Send Refund
                  </a>
                </Tooltip>
              )}
              {isLoading && (
                <div className="mt-2 ml-3 flex flex-wrap justify-center content-center">
                  <Spinner size="h-5 w-5" />
                </div>
              )}
            </div>
          </form>
          {/* errors will return when field validation fails  */}
          {refundError && <span>${refundError}</span>}
          {errors.refundAmount && <span>This field is required</span>}
        </>
      );
      break;
  }

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <a
          className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
          href={order.url}
          target="_blank"
          rel="noreferrer"
        >
          #{order.shopOrderNumber}
        </a>
        <div className="text-sm text-gray-500">
          {timeDifferenceForDate(Number(order.createdAt))}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div>
            <a
              className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
              href={`https://www.instagram.com/${order.instagramHandle}`}
              target="_blank"
              rel="noreferrer"
            >
              @{order.instagramHandle}
            </a>
            <div className="text-sm font-medium text-gray-900">{`${order.customer?.firstName} ${order.customer?.lastName}`}</div>
            <div className="text-sm text-gray-500">{`${
              order.customer?.email ? order.customer?.email + " - " : ""
            }${order.customer?.phoneNumber}`}</div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex flex-col text-sm">
          <div>
            <b>Total:</b> ${order.totalPrice}
          </div>
          <div>
            <b>Subtotal:</b> ${order.subtotalPrice}
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">{orderState}</td>
      <td className="px-6 py-4 whitespace-nowrap">{orderRefund}</td>
    </tr>
  );
}
