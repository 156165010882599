import { Link } from "react-router-dom";
import SignInForm, { SignInInputs } from "../components/forms/SignInForm";
import { auth } from "../config/firebase";

const SignIn = () => {
  const signIn = async (data: SignInInputs): Promise<[string, boolean]> => {
    try {
      await auth.signInWithEmailAndPassword(data.userEmail, data.userPassword);
      return [null, true];
    } catch (error) {
      console.error(error);
      return ["Error signing in with password and email", false];
    }
  };

  return (
    <div className="mt-8">
      <h1 className="text-3xl mb-2 text-center font-bold">Sign In</h1>
      <SignInForm submitForm={signIn} />
      {/* <p className="text-center my-3">or</p>
        <button
          className="bg-fb-blue hover:bg-blue-600 w-full py-2 text-white"
          onClick={() => signInWithFacebook()}
        >
          Login with Facebook
        </button> */}
      <p className="text-center my-3">
        Don't have an account?{" "}
        <Link to="/b/signup" className="text-blue-500 hover:text-blue-600">
          Sign up here
        </Link>{" "}
        <br />{" "}
        <Link
          to="/b/password-reset"
          className="text-blue-500 hover:text-blue-600"
        >
          Forgot Password?
        </Link>
      </p>
    </div>
  );
};
export default SignIn;
