const PrivacyPolicyPage = () => {
  return (
    <div>
      This Privacy Policy outlines important information regarding the use and
      disclosure of information collected via this website. Clado (Palette
      Technologies Inc.) provides this Privacy Policy to help users make an
      informed decision about whether or not to use this website. At our
      website, we recognize that privacy of your personal information is
      important. Here is information on what types of personal information we
      receive and collect when you use and visit our Website, and how we
      safeguard your information. We never sell your personal information to
      third parties.
      <div className="text-lg font-bold mt-2">Log Files</div>
      As with most other websites, we collect and use the data contained in log
      files. The information in the log files include your IP (internet
      protocol) address, your ISP (internet service provider, such as AOL or
      Shaw Cable), the browser you used to visit our site (such as Internet
      Explorer or Firefox), the time you visited our site and which pages you
      visited throughout our site.
      <div className="text-lg font-bold mt-2">Cookies and Web Beacons</div>
      We do use cookies to store information, such as your personal preferences
      when you visit our website. This could include only showing you a popup
      once in your visit, or the ability to login to some of our features, such
      as forums. We also use third party advertisements on our website to
      support our site. Some of these advertisers may use technology such as
      cookies and web beacons when they advertise on our site, which will also
      send these advertisers (such as Google through the Google AdSense program)
      information including your IP address, your ISP , the browser you used to
      visit our site, and in some cases, whether you have Flash installed. This
      is generally used for geotargeting purposes (showing New York real estate
      ads to someone in New York, for example) or showing certain ads based on
      specific sites visited (such as showing cooking ads to someone who
      frequents cooking sites). You can choose to disable or selectively turn
      off our cookies or third-party cookies in your browser settings, or by
      managing preferences in programs such as Norton Internet Security.
      However, this can affect how you are able to interact with our site as
      well as other websites. This could include the inability to login to
      services or programs, such as logging into forums or accounts.
      <div className="text-lg font-bold mt-2">
        Changes to this Privacy Policy
      </div>
      The contents of this policy may be altered at any time, at our discretion.
      If you have any questions regarding the Privacy Policy, then you may
      contact us at <a href="mailto:hello@clado.io">hello@clado.io</a>
      <div className="text-lg font-bold mt-2">Email addresses</div>
      Your email address may be shared with instructors that you take classes
      with. If you want to opt-out of this, you may contact us at{" "}
      <a href="mailto:hello@clado.io">hello@clado.io</a>
      <div className="text-lg font-bold mt-2">Third-Party Advertising</div>
      We may use third-party advertising companies to serve ads when you visit
      our Website. These companies may use aggregated information (not including
      your name, address, email address or telephone number) about your visits
      to this and other Websites in order to provide advertisements about goods
      and services of interest to you. If you would like more information about
      this practice and to know your choices about not having this information
      used by these companies, please see:
      https://optout.networkadvertising.org/
    </div>
  );
};

export default PrivacyPolicyPage;
