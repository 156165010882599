import { useContext, useEffect, useState } from "react";
import Dropdown from "../components/Dropdown";
import Loading from "../components/Loading";
import { auth, linkFacebook } from "../config/firebase";
import { UserContext } from "../providers/UserProvider";

const ProfilePage = () => {
  const { user, shopId, userRecord, setShopId } = useContext(UserContext);
  // const [isConnectedToFacebook, setIsConnectedToFacebook] = useState(false);
  const [activeOptionId, setActiveOptionId] = useState(null);
  const [activeOptionValue, setActiveOptionValue] = useState(null);

  // const clickConnectToFacebook = async () => {
  //   const result = await linkFacebook();
  //   setIsConnectedToFacebook(result);
  // };

  useEffect(() => {
    if (shopId) {
      setActiveOptionId(shopId);
      setActiveOptionValue(shopId);
    }
    return () => {};
  }, [user, shopId]);

  if (!user || !userRecord) {
    return <Loading />;
  }

  const photoURL = user?.photoURL;
  const displayName = user?.displayName;
  const email = user?.email;

  const avatar = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-11 w-11"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
      />
    </svg>
  );

  const photo = (
    <div
      style={{
        background: `url(${photoURL})  no-repeat center center`,
        backgroundSize: "cover",
        height: "60px",
        width: "60px",
      }}
      className="border border-blue-300"
    ></div>
  );

  const optionClicked = (option) => {
    setActiveOptionId(option.id);
    setActiveOptionValue(option.value);
    setShopId(option.id);
  };

  return (
    <div className="py-6">
      <div className="mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8">
        <div className="flex flex-col p-4 border border-gray-200 rounded-lg items-center md:flex-row md:items-start">
          {photoURL ? photo : avatar}
          <div className="md:pl-4">
            <h2 className="text-2xl font-semibold">{displayName}</h2>
            <h3 className="italic">{email}</h3>
          </div>
        </div>
        <div className="mt-4">
          <Dropdown
            options={userRecord.shops.map((shop) => {
              return { id: shop.id, value: shop.id };
            })}
            activeOptionId={activeOptionId}
            activeOptionValue={activeOptionValue}
            optionClicked={optionClicked}
          />
        </div>
        <button
          className="w-full py-3 bg-red-600 mt-4 text-white rounded"
          onClick={() => {
            auth.signOut();
          }}
        >
          Sign out
        </button>
      </div>
    </div>
  );
};
export default ProfilePage;
