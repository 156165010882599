import MediaInterface from "../interfaces/MediaInterface";

interface MentionedMediaCardProps {
  media: MediaInterface;
}

const MentionedMediaCard = ({ media }: MentionedMediaCardProps) => {
  var caption = media.caption ? media.caption : "(empty caption)";
  var captionLink = null;

  if (media.caption !== null || media.productType !== "STORY") {
    captionLink = (
      <div className="mb-2">
        <a
          className="font-bold text-xl inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 line-clamp-3"
          href={media.permalink}
          target="_blank"
          rel="noreferrer"
        >
          {caption}
        </a>
      </div>
    );
  }

  var mediaLink = (
    <a href={media.mediaUrl} target="_blank" rel="noreferrer">
      <img className="w-full" src={media.mediaUrl} alt={caption} />
    </a>
  );

  if (media.productType === "STORY") {
    mediaLink = (
      // <div className="filter blur-md">
      // <a href={media.mediaUrl} target="_blank" rel="noreferrer">
      //   <img onError={(e) => storyImageError(e)} className="w-full" src={media.mediaUrl} alt={caption} />
      // </a>
      // </div>
      <div className="w-80 h-80 flex flex-wrap justify-center content-center">
        <a
          className="max-h-11 text-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 border border-blue-500 hover:border-transparent rounded"
          href={media.mediaUrl}
          target="_blank"
          rel="noreferrer"
        >
          See story media
        </a>
      </div>
    );
  }

  return (
    <div className="max-w-xs rounded overflow-hidden shadow-lg">
      {mediaLink}
      <div className="px-6 py-4">
        {captionLink}
        <p className="text-gray-700 text-base">
          by{" "}
          <a
            className="inline-block align-baseline font-bold text-blue-500 hover:text-blue-800"
            href={`https://www.instagram.com/${media.username}`}
            target="_blank"
            rel="noreferrer"
          >
            {media.username}
          </a>
        </p>
      </div>
      <div className="px-6 pt-2 pb-2">
        {media.likeCount === 0 || media.likeCount > 0 ? (
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {media.likeCount} likes
          </span>
        ) : null}
        {media.commentsCount === 0 || media.commentsCount > 0 ? (
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {media.commentsCount} comments
          </span>
        ) : null}
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          {media.productType}
        </span>
        {media.mediaType ? (
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {media.mediaType}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default MentionedMediaCard;
