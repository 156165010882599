import MediaInterface from "../interfaces/MediaInterface";
import { timeDifferenceForDate } from "../utils/time";

interface MediaRowProps {
  media: MediaInterface;
}

const MediaRow = ({ media }: MediaRowProps) => {
  var captionLink = null;

  if (media.productType !== "STORY") {
    captionLink = (
      <a
        className="text-sm font-semibold text-indigo-600 hover:text-indigo-900 truncate"
        href={media.permalink}
        target="_blank"
        rel="noreferrer"
      >
        {media.caption ? media.caption : "(empty caption)"}
      </a>
    );
  }

  return (
    <tr>
      <td className="px-6 py-4 truncate">
        <a
          className="text-sm font-semibold text-indigo-600 hover:text-indigo-900 truncate"
          href={`https://www.instagram.com/${media.username}`}
          target="_blank"
          rel="noreferrer"
        >
          @{media.username}
        </a>
        <div className="text-sm text-gray-500">
          {timeDifferenceForDate(Number(media.createdAt))}
        </div>
      </td>
      <td className="max-w-xs px-6 py-4 truncate">{captionLink}</td>
      <td className="px-6 py-4 whitespace-nowrap">{media.mediaType}</td>
      <td className="px-6 py-4 whitespace-nowrap">{media.productType}</td>
      <td className="px-6 py-4 whitespace-nowrap">{media.likeCount}</td>
      <td className="px-6 py-4 whitespace-nowrap">{media.commentsCount}</td>
      <td className="px-6 py-4 whitespace-nowrap">
        <a
          className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
          href={media.mediaUrl}
          target="_blank"
          rel="noreferrer"
        >
          View media
        </a>
      </td>
    </tr>
  );
};

export default MediaRow;
