import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import CustomersList from "../components/CustomersList";
import Loading from "../components/Loading";
import { auth } from "../config/firebase";
import { GET_CUSTOMERS } from "../graphql/queries";
import { UserContext } from "../providers/UserProvider";
import ApiError from "../components/ApiError";

export default function CustomersPage() {
  const { shopId } = useContext(UserContext);
  const [getCustomers, { called, error, loading, data }] = useLazyQuery(
    GET_CUSTOMERS,
    {
      fetchPolicy: "network-only",
    }
  );

  const loadData = async () => {
    if (shopId) {
      const tokenId = await auth.currentUser.getIdToken();
      getCustomers({
        variables: {
          shopId,
        },
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
    }
  };

  useEffect(() => {
    loadData();
  }, [shopId]);

  if (loading || !called) return <Loading />;
  if (error) return <ApiError error={error} />;

  console.log(data);

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Customers</h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <CustomersList customerConnection={data.customers} />
        </div>
      </div>
    </div>
  );
}
