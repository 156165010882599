import { useApolloClient } from "@apollo/client";
import { Switch } from "@headlessui/react";
import { useContext, useState } from "react";
import { auth } from "../config/firebase";
import { UserContext } from "../providers/UserProvider";
import { classNames } from "../utils/style";
import MediaCard from "./MediaCard";
import MediaRow from "./MediaRow";
import Spinner from "./Spinner";

const MediaList = ({ mediaConnection, getMediaQuery, title, orderId }) => {
  const { shopId } = useContext(UserContext);
  const [isGridView, setIsGridView] = useState(false);
  const [edges, setEdges] = useState(mediaConnection.edges);
  const [after, setAfter] = useState(
    mediaConnection.edges[mediaConnection.edges.length - 1]?.cursor
  );
  const [hasNextPage, setHasNextPage] = useState(
    mediaConnection?.pageInfo.hasNextPage
  );
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();

  const toggleView = () => {
    setIsGridView(!isGridView);
  };

  const loadMoreMedia = async () => {
    setLoading(true);
    const tokenId = await auth.currentUser.getIdToken();
    const result = await apolloClient.query({
      query: getMediaQuery,
      variables: {
        shopId,
        first: 10,
        after,
        orderId,
      },
      context: {
        headers: {
          authorization: tokenId ? `Bearer ${tokenId}` : "",
        },
      },
    });
    const hasNextPage = result.data.tags?.pageInfo.hasNextPage
      ? result.data.tags?.pageInfo.hasNextPage
      : result.data.mentions?.pageInfo.hasNextPage;
    setHasNextPage(hasNextPage);

    const newEdges = result.data?.tags?.edges
      ? result.data?.tags.edges
      : result.data.mentions.edges;
    setAfter(newEdges[newEdges.length - 1].cursor);
    setEdges([...edges, ...newEdges]);
    setLoading(false);
  };

  const gridView = (
    <div className="flex flex-row flex-wrap gap-2 my-2">
      {edges.map((mediaEdge) => {
        return <MediaCard key={mediaEdge.node.id} media={mediaEdge.node} />;
      })}
    </div>
  );

  const listView = (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Username
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Caption
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Media Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Product Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Likes
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Comments
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Media
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {edges.map((edge) => (
                  <MediaRow key={edge.node.id} media={edge.node} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  var listFooter = null;
  if (hasNextPage) {
    listFooter = (
      <button
        className="py-2 px-6 text-center bg-transparent hover:bg-indigo-500 text-indigo-700 font-semibold hover:text-white border border-indigo-500 hover:border-transparent rounded"
        onClick={() => loadMoreMedia()}
      >
        See more
      </button>
    );
  }

  if (loading) {
    listFooter = (
      <div className="flex flex-wrap content-center">
        <Spinner size="h-6 w-6" />
      </div>
    );
  }

  const toggle = (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={isGridView}
        onChange={toggleView}
        className={classNames(
          isGridView ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            isGridView ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-gray-900">Grid view</span>
      </Switch.Label>
    </Switch.Group>
  );

  return (
    <div className="py-6">
      <div className="flex max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
        <div className="ml-5 flex justify-center">{toggle}</div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">
          {isGridView ? gridView : listView}
          <div className="my-4 flex justify-center">{listFooter}</div>
        </div>
      </div>
    </div>
  );
};

export default MediaList;
