import { useMutation } from "@apollo/client";
import { useContext } from "react";
import { useHistory } from "react-router";
import { auth } from "../config/firebase";
import { CHOOSE_FACEBOOK_ACCOUNT } from "../graphql/mutations";
import { UserContext } from "../providers/UserProvider";

export interface FacebookAccountProps {
  id: string;
  name: string;
  imageUrl: string;
}

const FacebookAccount = ({ id, name, imageUrl }: FacebookAccountProps) => {
  const [chooseFacebookAccount] = useMutation(CHOOSE_FACEBOOK_ACCOUNT);
  const { shopId } = useContext(UserContext);
  const history = useHistory();

  const selectFacebookAccount = async () => {
    try {
      console.log(id);
      const tokenId = await auth.currentUser.getIdToken();
      const result = await chooseFacebookAccount({
        variables: {
          facebookId: id,
          shopId,
        },
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
      console.log(result);
      history.replace("/b/");
    } catch (err) {
      console.error(err);
    }
  };

  if (!shopId) return <p>Loading...</p>;

  return (
    <div>
      <button
        className="flex items-stretch mt-4 w-full text-green-500 bg-transparent border border-solid border-green-500 hover:bg-green-500 hover:text-white active:bg-pink-600 font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
        onClick={() => selectFacebookAccount()}
      >
        <img className="h-20 mr-1" src={imageUrl} alt={"image for " + name} />
        <h1 className="text-lg self-center">{name}</h1>
      </button>
    </div>
  );
};

export default FacebookAccount;
