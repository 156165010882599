import { useMutation } from "@apollo/client";
import { useContext } from "react";
import { Link } from "react-router-dom";
import SignUpForm, { SignUpInputs } from "../components/forms/SignUpForm";
import { auth } from "../config/firebase";
import { CONNECT_PLACEHOLDER_SHOP } from "../graphql/mutations";
import { UserContext } from "../providers/UserProvider";

const SignUp = () => {
  const [connectPlaceholderShop] = useMutation(CONNECT_PLACEHOLDER_SHOP);
  const { shopId, setShopId } = useContext(UserContext);

  const createUser = async (data: SignUpInputs): Promise<[string, boolean]> => {
    try {
      await auth
        .createUserWithEmailAndPassword(data.userEmail, data.userPassword)
        .then(async (result) => {
          await result.user.updateProfile({
            displayName: data.userName,
          });
          const tokenId = await result.user.getIdToken();
          await connectPlaceholderShop({
            context: {
              headers: {
                authorization: tokenId ? `Bearer ${tokenId}` : "",
              },
            },
          });
          // The id of the placeholder shop is the same as the id of the user
          if (shopId !== result.user.uid) {
            setShopId(result.user.uid);
          }
        });
      return [null, true];
    } catch (error) {
      console.error(error);
      return [
        `Error signing up with email and password. ${error.message}`,
        false,
      ];
    }
  };

  return (
    <div className="mt-8">
      <h1 className="text-3xl mb-2 text-center font-bold">Sign Up</h1>
      <SignUpForm submitForm={createUser} />
      <p className="text-center my-3">
        Already have an account?{" "}
        <Link to="/" className="text-blue-500 hover:text-blue-600">
          Sign in here
        </Link>
      </p>
    </div>
  );
};
export default SignUp;
