import {
  CheckIcon,
  CurrencyDollarIcon,
  VideoCameraIcon,
  XIcon,
} from "@heroicons/react/outline";
import useLocationQuery from "../hooks/useLocationQuery";
import clutch from "../images/clutch.png";
import chanelia from "../images/chanelia.png";
import wildkat from "../images/wildkat.png";
import holi from "../images/holi.png";
import twice from "../images/twice.png";
import cheekybonsai from "../images/cheeky-bonsai.png";
import nouri from "../images/nouri.png";
import zera from "../images/zera.png";
import blancosshop from "../images/blancosshop.png";

export default function EducationPage() {
  const query = useLocationQuery();
  const shopId = query.get("shopId");
  const referrer = query.get("referrer");

  const closeFrame = () => {
    console.log("Closing modal at", referrer);
    window.parent.postMessage("close-edu-modal", referrer);
  };

  const [textColor, bgColor, pageTextColor, pageBgColor] = getColors(shopId);
  const features = getFeatures(shopId);
  const [imageSrc, imgHeight] = getImageSrc(shopId);
  const tagline = getTagline(shopId, textColor);
  const title = getTitle(shopId, textColor);
  const [howItWorks, shareAndEarn] = getHowItWorks(shopId);

  return (
    <div
      className={`flex flex-col min-h-screen pb-10 ${pageTextColor} ${pageBgColor}`}
    >
      <div className="flex justify-end w-full pt-5 px-8">
        <button className="" onClick={closeFrame}>
          <span>
            <XIcon className="h-7" />
          </span>
        </button>
      </div>
      <div className="flex justify-center w-full px-24 pt-3">
        {imageSrc && (
          <img
            className={`${imgHeight ? imgHeight : "max-h-10"}`}
            src={imageSrc}
          />
        )}
      </div>
      <div className="flex flex-col justify-center w-full px-8 pt-3">
        <h1 className="text-center font-bold text-lg">{title}</h1>
        <h2 className="text-center text-sm">{tagline}</h2>
        <div className="flex mt-5">
          <hr className={`${bgColor} w-full mt-5 mr-1 h-1 rounded`} />
          <div className="text-center font-bold text-base w-80">
            <h3>{howItWorks}</h3>
            <h3 className="text-center font-bold text-xs w-full">
              {shareAndEarn}
            </h3>
          </div>
          <hr className={`${bgColor} w-full mt-5 ml-1 h-1 rounded`} />
        </div>
        <div className="flex justify-center w-full"></div>
        <dl className="mt-5 space-y-5">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <div
                  className={`${bgColor} absolute h-12 w-12 flex items-center justify-center rounded-md`}
                >
                  <feature.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-1 ml-12 text-base">
                {/* {feature.description} */}
                <ul role="list" className="rounded flex flex-col">
                  {feature.checklist.map((item, index) => (
                    <li key={index} className="px-4 flex space-x-3 text-base">
                      <div>
                        <CheckIcon
                          className={`${textColor} h-6 w-6`}
                          aria-hidden="true"
                        />
                      </div>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}

function getFeatures(shopId: string) {
  switch (shopId) {
    case "clutchauthority.myshopify.com":
      return [
        {
          name: "Share a story or a feed post about Clutchpoints",
          icon: VideoCameraIcon,
          checklist: [
            "Tag @clutchpoints",
            "Within 30 days of ordering",
            "From a public Instagram account",
          ],
        },
        {
          name: "Earn cashback within 3 days",
          icon: CurrencyDollarIcon,
          checklist: [
            "Receive a refund on your original method of payment.",
            "Agree to Clutchpoints potentially repurposing your content for future marketing.",
          ],
        },
      ];
    case "chanelia.myshopify.com":
      return [
        {
          name: "Share a story or a feed post about ÇHANELIA",
          icon: VideoCameraIcon,
          checklist: [
            "Tag @_chanelia",
            "Within 30 days of ordering",
            "From a public Instagram account",
          ],
        },
        {
          name: "Earn cashback within 3 days",
          icon: CurrencyDollarIcon,
          checklist: [
            "Receive a refund on your original method of payment.",
            "Agree to ÇHANELIA potentially repurposing your content for future marketing.",
          ],
        },
      ];
    case "wildkat-bikinis.myshopify.com":
      return [
        {
          name: "Share a story or a feed post about Wildkat Bikinis",
          icon: VideoCameraIcon,
          checklist: [
            <>
              Tag{" "}
              <a
                href="https://www.instagram.com/wildkatbikinis/"
                className={`font-bold`}
                target="_blank"
              >
                @wildkatbikinis
              </a>
            </>,
            "Within 30 days of ordering",
            "From a public Instagram account",
          ],
        },
        {
          name: "Get a discount code to use on your next order",
          icon: CurrencyDollarIcon,
          checklist: [
            "We will send you a discount code within 3 days of posting.",
            "Agree to Wildkat Bikinis potentially repurposing your content for future marketing.",
          ],
        },
      ];
    case "cheeky-bonsai.myshopify.com":
      return [
        {
          name: "Share a story or a feed post about Cheeky Bonsai",
          icon: VideoCameraIcon,
          checklist: [
            <>
              Tag{" "}
              <a
                href="https://www.instagram.com/cheekybonsai/"
                className={`font-bold`}
                target="_blank"
              >
                @cheekybonsai
              </a>
            </>,
            "Within 30 days of ordering",
            "From a public Instagram account",
          ],
        },
        {
          name: "Earn cashback within 3 days",
          icon: CurrencyDollarIcon,
          checklist: [
            "Receive a refund on your original method of payment.",
            "Agree to Cheeky Bonsai potentially repurposing your content for future marketing.",
          ],
        },
      ];
    case "twice-zero.myshopify.com":
      return [
        {
          name: "Share a story or a feed post about Twice",
          icon: VideoCameraIcon,
          checklist: [
            <>
              Tag{" "}
              <a
                href="https://www.instagram.com/twice/"
                className={`font-bold`}
                target="_blank"
              >
                @twice
              </a>
            </>,
            "Within 30 days of ordering",
            "From a public Instagram account",
          ],
        },
        {
          name: "Get rewarded & save money",
          icon: CurrencyDollarIcon,
          checklist: [
            "We will send you your reward within 3 days of posting",
            "Agree to our brand potentially repurposing your content for future marketing",
          ],
        },
      ];
    case "zera-life.myshopify.com":
      return [
        {
          name: "Share a story or a feed post about Kudos",
          icon: VideoCameraIcon,
          checklist: [
            <>
              Tag{" "}
              <a
                href="https://www.instagram.com/kudostobaby/"
                className={`font-bold`}
                target="_blank"
              >
                @kudostobaby
              </a>
            </>,
            "Within 30 days of ordering",
            "From a public Instagram account",
          ],
        },
        {
          name: "Get rewarded & save money",
          icon: CurrencyDollarIcon,
          checklist: [
            "We will send you your reward within 3 days of posting",
            "Agree to our brand potentially repurposing your content for future marketing",
          ],
        },
      ];
    case "blancosshop.myshopify.com":
      return [
        {
          name: "Teile eine Story oder ein Feed mit deinen neuen Produtkten",
          icon: VideoCameraIcon,
          checklist: [
            <>
              Markiere{" "}
              <a
                href="https://www.instagram.com/blancosart/"
                className={`font-bold`}
                target="_blank"
              >
                @blancosart
              </a>
            </>,
            "Innerhalb 30 Tagen",
            "Von einem öffentlichen Instagram-Account",
          ],
        },
        {
          name: "Werde belohnt und spare Geld!",
          icon: CurrencyDollarIcon,
          checklist: [
            "Dein Rabattcode senden wir dir innerhalb 3 Tagen zu",
            "Stimme zu das wir dein Bild möglicherweise für Marketingzwecke reposten dürfen",
          ],
        },
      ];
    default:
      return [
        {
          name: "Share a story or a feed post about your purchase",
          icon: VideoCameraIcon,
          checklist: [
            "Tag our Instagram account",
            "Within 30 days of ordering",
            "From a public Instagram account",
          ],
        },
        {
          name: "Get rewarded & save money",
          icon: CurrencyDollarIcon,
          checklist: [
            "We will send you your reward within 3 days of posting",
            "Agree to our brand potentially repurposing your content for future marketing",
          ],
        },
      ];
  }
}

function getImageSrc(shopId: string) {
  switch (shopId) {
    case "holi-chow.myshopify.com":
      return [holi, null];
    case "twice-zero.myshopify.com":
      return [twice, null];
    case "clutchauthority.myshopify.com":
      return [clutch, null];
    case "chanelia.myshopify.com":
      return [chanelia, null];
    case "wildkat-bikinis.myshopify.com":
      return [wildkat, null];
    case "cheeky-bonsai.myshopify.com":
      return [cheekybonsai, null];
    case "nouri-mama.myshopify.com":
      return [nouri, null];
    case "zera-life.myshopify.com":
      return [zera, null];
    case "blancosshop.myshopify.com":
      return [blancosshop, "max-h-32"];
    default:
      return [null, null];
  }
}

function getTagline(shopId: string, textColor: string) {
  switch (shopId) {
    case "clutchauthority.myshopify.com":
      return (
        <div>
          When you are rocking our gear, tag{" "}
          <a
            href="https://www.instagram.com/clutchpoints/"
            className={`${textColor} font-bold`}
            target="_blank"
          >
            @clutchpoints
          </a>
          .
        </div>
      );
    case "chanelia.myshopify.com":
      return (
        <div>
          When you are rocking our clothes, tag{" "}
          <a
            href="https://www.instagram.com/_chanelia/"
            className={`${textColor} font-bold`}
            target="_blank"
          >
            @_chanelia
          </a>
          .
        </div>
      );
    case "cheeky-bonsai.myshopify.com":
      return (
        <div>
          We want to see you shine. Get 10% back when you show us your love for
          Cheeky Bonsai and tag{" "}
          <a
            href="https://www.instagram.com/cheekybonsai/"
            className={`${textColor} font-bold`}
            target="_blank"
          >
            @cheekybonsai
          </a>
          .
        </div>
      );
    case "zera-life.myshopify.com":
      return (
        <div>
          Get 15% off your order when you tag{" "}
          <a
            href="https://www.instagram.com/kudostobaby/"
            className={`${textColor} font-bold`}
            target="_blank"
          >
            @kudostobaby
          </a>
          .
        </div>
      );
    case "wildkat-bikinis.myshopify.com":
      return (
        <div>
          We want to see you shine. Get 20% off your next order when you tag{" "}
          <a
            href="https://www.instagram.com/wildkatbikinis/"
            className={`${textColor} font-bold`}
            target="_blank"
          >
            @wildkatbikinis
          </a>
          .
        </div>
      );
    case "twice-zero.myshopify.com":
      return (
        <div>
          We want to see you smile. Get 10% off your order back when you tag{" "}
          <a
            href="https://www.instagram.com/twice/"
            className={`${textColor} font-bold`}
            target="_blank"
          >
            @twice
          </a>
          .
        </div>
      );
    case "nouri-mama.myshopify.com":
      return (
        <div>
          Help us get the word out to more families, and get rewarded for it!
        </div>
      );
    case "blancosshop.myshopify.com":
      return <div>Teile deine Freude und werde belohnt!</div>;
    default:
      return <div>We want to see you shine. Get rewarded when you tag us.</div>;
  }
}

// Highlighted text, color for lines, overall text color, background page color
function getColors(shopId: string) {
  switch (shopId) {
    case "clutchauthority.myshopify.com":
      return ["text-indigo-500", "bg-indigo-500", "text-gray-900", ""];
    case "chanelia.myshopify.com":
      return ["text-pink-500", "bg-pink-500", "text-gray-900", ""];
    case "wildkat-bikinis.myshopify.com":
      return ["text-white", "", "text-white", "bg-black"];
    case "cheeky-bonsai.myshopify.com":
      return [
        "text-cheeky-bonsai",
        "bg-cheeky-bonsai",
        "text-cheeky-bonsai",
        "bg-cheeky-bonsai-2",
      ];
    case "zera-life.myshopify.com":
      return ["text-kudos", "bg-kudos", "text-gray-900", ""];
    case "twice-zero.myshopify.com":
      return ["text-twice", "bg-twice", "text-gray-900", ""];
    case "blancosshop.myshopify.com":
      return ["text-blancosshop", "bg-blancosshop", "text-gray-900", ""];
    default:
      return ["text-indigo-500", "bg-indigo-500", "text-gray-900", ""];
  }
}
function getTitle(shopId: string, textColor: string) {
  switch (shopId) {
    case "clutchauthority.myshopify.com":
      return (
        <>
          Get up to <span className={`${textColor}`}>15% back</span> on your
          order.
        </>
      );
    case "wildkat-bikinis.myshopify.com":
      return (
        <>
          Get wild and get <span className={`${textColor}`}>20% off!</span>
        </>
      );
    case "zera-life.myshopify.com":
      return <>Your support means the world to us.</>;
    case "nouri-mama.myshopify.com":
      return <>Love Nouri?</>;
    case "twice-zero.myshopify.com":
      return <>Get 10% cashback!</>;
    case "blancosshop.myshopify.com":
      return <>Sichere dir 15% Rabatt!</>;
    default:
      return <>We want to reward you!</>;
  }
}

function getHowItWorks(shopId: string): [string, string] {
  switch (shopId) {
    case "blancosshop.myshopify.com":
      return ["So funktioniert es", "Teilen & verdienen"];
    default:
      return ["How it works", "Share and Earn"];
  }
}
