import { gql } from "@apollo/client";

export const GET_USER = gql`
  query GetUser {
    getUser {
      id
      email
      name
      pictureUrl
      shops {
        id
        instagramId
        facebookId
        hasFacebookAccessToken
      }
    }
  }
`;

export const GET_SETTINGS = gql`
  query Settings($shopId: ID!) {
    settings(shopId: $shopId) {
      isShopifyConnected
      isFacebookConnected
      isInstagramConnected
      isInstagramHandleScriptTagInstalled
      instagramHandle
      maxCashReward
      maxPercentageReward
      rewardType
      tagline
    }
  }
`;

export const GET_MENTIONS = gql`
  query Mentions($shopId: ID!, $first: Int, $after: String, $orderId: ID) {
    mentions(shopId: $shopId, first: $first, after: $after, orderId: $orderId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          caption
          commentsCount
          likeCount
          mediaType
          permalink
          mediaUrl
          productType
          createdAt
          username
          videoTitle
        }
      }
    }
  }
`;

export const GET_TAGS = gql`
  query Tags($shopId: ID!, $first: Int, $after: String) {
    tags(shopId: $shopId, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          caption
          commentsCount
          likeCount
          mediaType
          permalink
          mediaUrl
          productType
          createdAt
          username
          videoTitle
        }
      }
    }
  }
`;

export const GET_STORE_WITH_MESSAGES = gql`
  query GetShop($shopId: ID!) {
    getShop(shopId: $shopId) {
      id
      shopifyDomainName
      instagramId
      facebookId
      hasFacebookAccessToken
      threads {
        id
        participants {
          id
          username
        }
        messages {
          id
          text
          from
          createdAt
          storyUrl
        }
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query Customers($shopId: ID!, $first: Int, $after: String) {
    customers(shopId: $shopId, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          firstName
          lastName
          email
          phoneNumber
          createdAt
          igUsername
          instagramUserData {
            username
            biography
            businessEmail
            externalUrl
            followers
            following
            isPrivate
            isVerified
            postsCount
            profileImageLink
            profileName
          }
        }
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query Orders($shopId: ID!, $first: Int, $after: String) {
    orders(shopId: $shopId, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          shopId
          shopOrderId
          shopOrderNumber
          instagramHandle
          url
          state
          createdAt
          customer {
            id
            firstName
            lastName
            email
            phoneNumber
            createdAt
          }
          discountCode
          refundAmount
          subtotalPrice
          totalPrice
        }
      }
    }
  }
`;

export const GET_FB_ACCOUNTS = gql`
  query GetFacebookAccounts($shopId: ID!) {
    getFacebookAccounts(shopId: $shopId) {
      id
      name
      imageUrl
    }
  }
`;
