import { Switch, Route } from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import PasswordReset from "./PasswordReset";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../providers/UserProvider";
import MainPage from "./MainPage";
import ProfilePage from "./ProfilePage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import ShopifyCallbackPage from "./ShopifyCallbackPage";
import FacebookConnectPage from "./FacebookConnectPage";
import MentionsPage from "./MentionsPage";
import MessagesPage from "./MessagesPage";
import OrdersPage from "./OrdersPage";
import TagsPage from "./TagsPage";
import CustomersPage from "./CustomersPage";
import Sidebar from "../components/navbar/Sidebar";
import EducationPage from "./EducationPage";

function Application() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true"
  );
  const { user } = useContext(UserContext);
  const loggedInSwitch = (
    <Switch>
      <Route path="/b">
        <div className="h-screen flex overflow-hidden bg-white">
          <Sidebar />
          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
              <Switch>
                <Route path="/b/profile">
                  <ProfilePage />
                </Route>
                <Route path="/b/privacy-policy">
                  <PrivacyPolicyPage />
                </Route>
                <Route path="/b/callback/shopify">
                  <ShopifyCallbackPage />
                </Route>
                <Route path="/b/connect/facebook">
                  <FacebookConnectPage />
                </Route>
                <Route path="/b/mentions">
                  <MentionsPage />
                </Route>
                <Route path="/b/tags">
                  <TagsPage />
                </Route>
                <Route path="/b/customers">
                  <CustomersPage />
                </Route>
                <Route path="/b/messages/:id">
                  <MessagesPage />
                </Route>
                <Route path="/b/messages">
                  <MessagesPage />
                </Route>
                <Route path="/b/orders">
                  <OrdersPage />
                </Route>
                <Route path="/b/">
                  <MainPage />
                </Route>
              </Switch>
            </main>
          </div>
        </div>
      </Route>
      <Route path="/e">
        <EducationPage />
      </Route>
      <Route path="/">
        <div className="h-screen flex overflow-hidden bg-white">
          <Sidebar />
          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
              <MainPage />
            </main>
          </div>
        </div>
      </Route>
    </Switch>
  );

  const loggedOutSwitch = (
    <Switch>
      <Route path="/b">
        <div className="container mx-auto md:max-w-screen-lg md:px-0 px-2">
          <Switch>
            <Route path="/b/signup">
              <SignUp />
            </Route>
            <Route path="/b/password-reset">
              <PasswordReset />
            </Route>
            <Route path="/b/privacy-policy">
              <PrivacyPolicyPage />
            </Route>
            <Route path="/b/callback/shopify">
              <ShopifyCallbackPage />
            </Route>
            <Route path="/b/">
              <SignIn />
            </Route>
          </Switch>
        </div>
      </Route>
      <Route path="/e">
        <EducationPage />
      </Route>
      <Route path="/">
        <div className="container mx-auto md:max-w-screen-lg md:px-0 px-2">
          <SignIn />
        </div>
      </Route>
    </Switch>
  );

  useEffect(() => {
    setIsAuthenticated(localStorage.getItem("authenticated") === "true");
    return () => {};
  }, [user]);

  return isAuthenticated ? (
    <div>{loggedInSwitch}</div>
  ) : (
    <div>{loggedOutSwitch}</div>
  );
}
export default Application;
