import { useContext, useEffect, useState } from "react";
import Loading from "../components/Loading";
import { UserContext } from "../providers/UserProvider";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { useLazyQuery } from "@apollo/client";
import { GET_SETTINGS } from "../graphql/queries";
import { useHistory } from "react-router-dom";
import { auth } from "../config/firebase";
import ApiError from "../components/ApiError";
import RewardForm from "../components/forms/RewardForm";
import ShopifyScriptInstallStep from "../components/ShopifyScriptInstallStep";

export default function MainPage() {
  const { shopId } = useContext(UserContext);
  const history = useHistory();

  const [isFacebookConnected, setIsFacebookConnected] = useState(false);
  const [isInstagramConnected, setIsInstagramConnected] = useState(false);
  const [
    isInstagramHandleScriptTagInstalled,
    setIsInstagramHandleScriptTagInstalled,
  ] = useState(false);
  const [isShopifyConnected, setIsShopifyConnected] = useState(false);
  const [maxCashReward, setMaxCashReward] = useState();
  const [maxPercentageReward, setMaxPercentageReward] = useState();
  const [rewardType, setRewardType] = useState();

  const [shopIgHandle, setShopIgHandle] = useState<string>("");
  const [shopTagline, setShopTagline] = useState<string>(null);
  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);

  const onSaveClicked = (data) => {
    setIsSaveClicked(true);
    setMaxCashReward(data.maxCashReward);
    setMaxPercentageReward(data.maxPercentageReward);
    setRewardType(data.rewardType);
    setShopTagline(data.tagline);
  };

  const setSettings = (data) => {
    setIsShopifyConnected(data.settings.isShopifyConnected);
    setIsInstagramConnected(data.settings.isInstagramConnected);
    setIsFacebookConnected(data.settings.isFacebookConnected);
    setIsInstagramHandleScriptTagInstalled(
      data.settings.isInstagramHandleScriptTagInstalled
    );
    setMaxCashReward(data.settings.maxCashReward);
    setMaxPercentageReward(data.settings.maxPercentageReward);
    setRewardType(data.settings.rewardType);
    setShopTagline(data.settings.tagline);
    setShopIgHandle(data.settings.instagramHandle);
  };

  const [getSettings, { called, error, loading }] = useLazyQuery(GET_SETTINGS, {
    fetchPolicy: "network-only",
    onCompleted: setSettings,
  });

  useEffect(() => {
    const loadData = async () => {
      if (shopId) {
        const tokenId = await auth.currentUser.getIdToken();
        getSettings({
          variables: {
            shopId,
          },
          context: {
            headers: {
              authorization: tokenId ? `Bearer ${tokenId}` : "",
            },
          },
        });
      }
    };
    loadData();
  }, [shopId]);

  const connectToFacebook = () => {
    history.push("/b/connect/facebook");
  };

  if (!shopId || loading || !called) return <Loading />;
  if (error) return <ApiError error={error} />;

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Welcome to Clado!
        </h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <div className="p-4 border border-gray-200 rounded-lg">
            <h2 className="text-xl font-semibold text-gray-900">
              Let's get you started by following this guide
            </h2>
            <div className="mt-4">
              <dl className="space-y-0 grid grid-cols-1 gap-y-5">
                <div className="relative">
                  <dt>
                    <div
                      className={`absolute flex items-center justify-center h-8 w-8 rounded-md ${
                        isFacebookConnected && isInstagramConnected
                          ? "bg-green-400"
                          : "bg-green-400"
                      } text-white`}
                    >
                      1
                    </div>
                    <p className="flex ml-10 text-lg leading-6 font-medium text-gray-900">
                      Connect to Instagram
                      <span className="ml-1">
                        {isFacebookConnected && isInstagramConnected && (
                          <CheckCircleIcon className="text-green-500 mr-4 flex-shrink-0 h-5 w-5" />
                        )}
                      </span>
                    </p>
                  </dt>
                  <dd className="ml-10 text-base text-gray-500">
                    <h3 className="text-sm font-semibold text-gray-800 underline">
                      Listen to your social chatter
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      We need access to your Instagram account so Clado can
                      listen to the posts (feed, stories) that mention your
                      brand.
                    </p>
                    <div className="flex">
                      {(!isFacebookConnected || !isInstagramConnected) && (
                        <button
                          type="button"
                          onClick={() => connectToFacebook()}
                          className="text-center mt-1 disabled:opacity-50 text-xs bg-transparent hover:bg-indigo-500 text-indigo-700 font-semibold hover:text-white py-2 px-2 border border-indigo-500 hover:border-transparent rounded"
                        >
                          Connect with Facebook
                        </button>
                      )}
                    </div>
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <div
                      className={`absolute flex items-center justify-center h-8 w-8 rounded-md ${
                        isShopifyConnected ? "bg-green-400" : "bg-gray-400"
                      } text-white`}
                    >
                      2
                    </div>
                    <p className="flex ml-10 text-lg leading-6 font-medium text-gray-900">
                      Install Shopify Plugin
                      <span className="ml-1">
                        {isShopifyConnected && (
                          <CheckCircleIcon className="text-green-500 mr-4 flex-shrink-0 h-5 w-5" />
                        )}
                      </span>
                    </p>
                  </dt>
                  <dd className="ml-10 text-base text-gray-500">
                    <h3 className="text-sm font-semibold text-gray-800 underline">
                      Get to know your customers through their social presence
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      We ask you for permission to read your orders and
                      customers so we can match their orders with their
                      Instagram handle, know when they tag your Instagram
                      account and give you the option to reward them with
                      cashback or a future discount code.
                    </p>
                    {!isShopifyConnected && (
                      <a
                        className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
                        href={`https://apps.shopify.com/clado`}
                      >
                        Install Shopify plugin
                      </a>
                    )}
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <div
                      className={`absolute flex items-center justify-center h-8 w-8 rounded-md ${
                        maxCashReward || maxPercentageReward
                          ? "bg-green-400"
                          : "bg-green-400"
                      } text-white`}
                    >
                      3
                    </div>
                    <p className="flex ml-10 text-lg leading-6 font-medium text-gray-900">
                      Choose your reward
                      <span className="ml-1">
                        {isSaveClicked && (
                          <CheckCircleIcon className="text-green-500 mr-4 flex-shrink-0 h-5 w-5" />
                        )}
                      </span>
                    </p>
                  </dt>
                  <dd className="flex flex-col ml-10 text-base text-gray-500">
                    <h3 className="text-sm font-semibold text-gray-800 underline">
                      We use machine learning and AI to identify your superfans
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      We automatically send your customers the incentive after
                      they tag you on Instagram. We use machine learning to
                      score your customer and decide how much they should get
                      rewarded based on their social clout and their affinity to
                      your brand.
                    </p>
                    <RewardForm
                      maxCashReward={maxCashReward}
                      maxPercentageReward={maxPercentageReward}
                      rewardType={rewardType}
                      tagline={shopTagline}
                      igHandle={shopIgHandle}
                      onSave={onSaveClicked}
                      onChangeTagline={(tagline) => {
                        setShopTagline(tagline);
                      }}
                    />
                  </dd>
                </div>
                <div className="relative">
                  <ShopifyScriptInstallStep
                    shopId={shopId}
                    shopIgHandle={shopIgHandle}
                    shopTagline={shopTagline}
                    isScriptInstalled={isInstagramHandleScriptTagInstalled}
                  />
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
