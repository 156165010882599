import gql from "graphql-tag";

export const UPDATE_FACEBOOK_ACCESS_TOKEN = gql`
  mutation UpdateFacebookAccessToken(
    $accessToken: String!
    $scopes: String!
    $shopId: ID!
  ) {
    updateFacebookAccessToken(
      accessToken: $accessToken
      scopes: $scopes
      shopId: $shopId
    )
  }
`;

export const CHOOSE_FACEBOOK_ACCOUNT = gql`
  mutation ChooseFacebookAccount($facebookId: ID!, $shopId: ID!) {
    chooseFacebookAccount(facebookId: $facebookId, shopId: $shopId)
  }
`;

export const POST_REFUND = gql`
  mutation PostRefund($shopId: ID!, $orderId: String!, $amount: Int!) {
    postRefund(shopId: $shopId, orderId: $orderId, amount: $amount)
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation UpdateSettings(
    $shopId: ID!
    $maxCashReward: Float
    $maxPercentageReward: Float
    $rewardType: String
    $tagline: String
  ) {
    updateSettings(
      shopId: $shopId
      maxCashReward: $maxCashReward
      maxPercentageReward: $maxPercentageReward
      rewardType: $rewardType
      tagline: $tagline
    )
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage(
    $facebookId: String!
    $recipientId: String!
    $text: String!
    $shopId: ID!
  ) {
    sendMessage(
      facebookId: $facebookId
      recipientId: $recipientId
      text: $text
      shopId: $shopId
    )
  }
`;

export const ADD_INSTAGRAM_HANDLE_SCRIPT = gql`
  mutation AddInstagramHandleScript($shopId: ID!) {
    addInstagramHandleScript(shopId: $shopId)
  }
`;

export const REMOVE_INSTAGRAM_HANDLE_SCRIPT = gql`
  mutation RemoveInstagramHandleScript($shopId: ID!) {
    removeInstagramHandleScript(shopId: $shopId)
  }
`;

export const CONNECT_PLACEHOLDER_SHOP = gql`
  mutation ConnectPlaceholderShop {
    connectPlaceholderShop
  }
`;

export const CONNECT_STORE = gql`
  mutation ConnectStore(
    $shop: String!
    $code: String!
    $hmac: String!
    $host: String!
    $timestamp: String!
    $state: String!
  ) {
    connectStore(
      shop: $shop
      code: $code
      hmac: $hmac
      host: $host
      timestamp: $timestamp
      state: $state
    )
  }
`;
