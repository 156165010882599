import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { UPDATE_FACEBOOK_ACCESS_TOKEN } from "../graphql/mutations";
import apolloClient from "./apolloClient";

const firebaseConfig = {
  apiKey: "AIzaSyD50iC47i0mcGTJG_2WHHJmPRsNoBRjE9o",
  authDomain: "clado-app.firebaseapp.com",
  projectId: "clado-app",
  storageBucket: "clado-app.appspot.com",
  messagingSenderId: "6906346436",
  appId: "1:6906346436:web:b6376e48dd38bb49df4291",
  measurementId: "G-QEK4X71ZWC",
};

firebase.initializeApp(firebaseConfig);

const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const facebookScopes = new Set<string>();
// Setting up access to instagram
facebookScopes.add("instagram_basic");
facebookScopes.add("pages_show_list");

// Manage messages
facebookScopes.add("instagram_manage_messages");

// Setting up webhooks
// https://developers.facebook.com/docs/instagram-api/guides/webhooks
facebookScopes.add("instagram_manage_insights");
facebookScopes.add("instagram_manage_comments");
facebookScopes.add("pages_manage_metadata");

facebookScopes.forEach(function (scope) {
  facebookProvider.addScope(scope);
});

async function linkFacebookAccount(result: firebase.auth.UserCredential) {
  // This gives you a Facebook Access Token.
  const oauthCredential = result.credential as firebase.auth.OAuthCredential;
  const accessToken = oauthCredential?.accessToken;

  const tokenId = await result.user?.getIdToken();

  console.log("Updating facebook access token");

  const updateAccessTokenResult = await apolloClient.mutate({
    context: {
      headers: {
        authorization: tokenId ? `Bearer ${tokenId}` : "",
      },
    },
    variables: {
      accessToken,
      scopes: Array.from(facebookScopes).join(","),
    },
    mutation: UPDATE_FACEBOOK_ACCESS_TOKEN,
  });
  return updateAccessTokenResult?.data?.updateFacebookAccessToken === true;
}

export const signInWithFacebook = () => {
  auth
    .signInWithPopup(facebookProvider)
    .then(linkFacebookAccount)
    .catch((err) => console.error(err));
};

export const linkFacebook = () => {
  const result = auth.currentUser
    .linkWithPopup(facebookProvider)
    .then(linkFacebookAccount)
    .catch((err) => {
      console.error(err);
      return false;
    });
  return result;
};

async function linkShopifyAccount(result: firebase.auth.UserCredential) {
  console.log(result);
  return true;
}

// https://shopify.dev/tutorials/authenticate-with-oauth
export const linkShopify = (token: string) => {
  const result = auth
    .signInWithCustomToken(token)
    .then(linkShopifyAccount)
    .catch((err) => {
      console.error(err);
      return false;
    });
  return result;
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();
