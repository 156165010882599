import { useMutation } from "@apollo/client";
import { RadioGroup, Switch } from "@headlessui/react";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { auth } from "../../config/firebase";
import { UPDATE_SETTINGS } from "../../graphql/mutations";
import { UserContext } from "../../providers/UserProvider";
import { classNames } from "../../utils/style";
import Spinner from "../Spinner";

type Inputs = {
  maxCashReward: string;
  maxPercentageReward: string;
  rewardType: string;
  tagline: string;
};

type RewardFormProps = {
  maxCashReward: string;
  maxPercentageReward: string;
  rewardType: string;
  tagline: string;
  igHandle: string;
  onChangeTagline: (tagline: string) => void;
  onSave: (any) => void;
};

const plans = [
  { name: "Cashback Reward", type: "cash" },
  { name: "Future Discount Reward", type: "discount" },
];

export default function RewardForm(values: RewardFormProps) {
  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      maxCashReward: values.maxCashReward,
      maxPercentageReward: values.maxPercentageReward,
    },
  });
  const { shopId } = useContext(UserContext);
  const [updateSettings] = useMutation(UPDATE_SETTINGS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState<boolean>(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [tagline, setTagline] = useState<string>(values.tagline);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "tagline") {
        setTagline(value.tagline);
        values.onChangeTagline(value.tagline);
      }
    });
    reset({
      maxCashReward: values.maxCashReward,
      maxPercentageReward: values.maxPercentageReward,
    });
    const planIndex = plans.findIndex((plan) => {
      return plan.type === values.rewardType;
    });
    setSelectedPlan(plans[planIndex]);
    setTagline(values.tagline);
    return () => subscription.unsubscribe();
  }, [values]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    setIsLoading(true);
    setIsSaveButtonEnabled(false);
    const tokenId = await auth.currentUser.getIdToken();
    const params = {
      maxCashReward: parseFloat(data.maxCashReward),
      maxPercentageReward: parseFloat(data.maxPercentageReward),
      rewardType: selectedPlan?.type ? selectedPlan.type : "cash",
      tagline: data.tagline,
    };
    try {
      const result = await updateSettings({
        variables: {
          shopId,
          ...params,
        },
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
      console.log(result);
      values.onSave(params);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      setIsSaveButtonEnabled(true);
    }
  };

  return (
    <>
      <p className="mt-1 text-sm text-gray-600 font-bold">
        You can select your incentive: cashback or a future discount.
      </p>
      <div className="mt-2">
        <RadioGroup value={selectedPlan} onChange={setSelectedPlan}>
          <RadioGroup.Label className="sr-only">
            Reward Options
          </RadioGroup.Label>
          <div className="flex flex-col sm:flex-row bg-white rounded-md">
            {plans.map((plan, planIdx) => (
              <RadioGroup.Option
                key={plan.name}
                value={plan}
                className={({ checked }) =>
                  classNames(
                    checked
                      ? "bg-indigo-50 border-indigo-200 z-10"
                      : "border-gray-200",
                    "rounded-md relative border p-3 flex flex-col mr-2 cursor-pointer md:pl-4 md:pr-6 focus:outline-none"
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex items-center text-sm">
                      <span
                        className={classNames(
                          checked
                            ? "bg-indigo-600 border-transparent"
                            : "bg-white border-gray-300",
                          active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                          "h-4 w-4 rounded-full border flex items-center justify-center"
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          checked ? "text-indigo-900" : "text-gray-900",
                          "ml-3 font-medium"
                        )}
                      >
                        {plan.name}
                      </RadioGroup.Label>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
        <p className="mt-1 text-sm text-gray-600">
          What is the maximum cash or future discount you are willing to give
          back per order? (If you set both we will use the larger value as our
          upper bound. For example if an order is $100 and the maximum cash
          reward is $10 but the percentage reward is 20%, the order would
          qualify for a $20 cash reward)
        </p>
        <form
          className="mt-2 flex flex-col space-y-2 items-start content-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0 items-start content-center">
            <div className="w-full lg:w-1/2 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                disabled={!isSaveButtonEnabled}
                type="number"
                step="0.01"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 py-1 sm:text-sm border-gray-300 rounded-md border"
                placeholder="0.00"
                defaultValue={values.maxCashReward ? values.maxCashReward : ""}
                {...register("maxCashReward")}
                aria-describedby="refund-currency"
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm" id="refund-currency">
                  USD
                </span>
              </div>
            </div>
            <div className="w-full lg:w-1/2 relative rounded-md ">
              <input
                disabled={!isSaveButtonEnabled}
                type="number"
                step="0.01"
                max="100"
                min="0"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-3 pr-12 py-1 sm:text-sm border-gray-300 rounded-md border"
                placeholder="0.00"
                defaultValue={
                  values.maxPercentageReward ? values.maxPercentageReward : ""
                }
                {...register("maxPercentageReward")}
                aria-describedby="refund-currency"
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm" id="refund-currency">
                  %
                </span>
              </div>
            </div>
          </div>
          <div className="w-full md:w-2/3">
            <label htmlFor="tagline" className="text-sm font-medium text-black">
              Tagline for your cart plugin:
            </label>
            <div className="mt-1">
              <input
                id="tagline"
                name="tagline"
                type="text"
                onChange={(event) => {
                  console.log(event);
                }}
                defaultValue={values.tagline ? values.tagline : ""}
                {...register("tagline")}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-black focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
              />
            </div>
          </div>
          <div className="w-full text-sm">
            Copy for your cart plugin:{" "}
            <p className="text-black">
              {tagline} tag{" "}
              <span className="font-bold text-sm">@{values.igHandle}. ⓘ</span>
            </p>
          </div>
          <button
            disabled={!isSaveButtonEnabled}
            className="w-full md:w-1/6 p-1 text-sm text-center bg-transparent hover:bg-indigo-500 text-indigo-700 font-semibold hover:text-white border border-indigo-500 hover:border-transparent rounded disabled:opacity-50 disabled:bg-indigo-600 disabled:text-white"
            type="submit"
          >
            Save
          </button>
          {isLoading && (
            <div className="mt-2 ml-3 flex flex-wrap justify-center content-center">
              <Spinner size="h-5 w-5" />
            </div>
          )}
        </form>
      </div>
    </>
  );
}
