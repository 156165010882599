import { useMutation } from "@apollo/client";
import firebase from "firebase";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SignInForm from "../components/forms/SignInForm";
import SignUpForm, { SignUpInputs } from "../components/forms/SignUpForm";
import Loading from "../components/Loading";
import { auth } from "../config/firebase";
import { CONNECT_STORE } from "../graphql/mutations";
import userLocationQuery from "../hooks/useLocationQuery";
import { UserContext } from "../providers/UserProvider";

const ShopifyCallbackPage = () => {
  const query = userLocationQuery();
  const [isSignUpFlow, setIsSignUpFlow] = useState(true);
  const [connectStoreMutation] = useMutation(CONNECT_STORE);
  const { user, shopId, setShopId } = useContext(UserContext);
  const history = useHistory();
  const shop = query.get("shop");
  const code = query.get("code");
  const hmac = query.get("hmac");
  const host = query.get("host");
  const timestamp = query.get("timestamp");
  const state = query.get("state");

  const connectLoggedInUser = async () => {
    if (user) {
      // Logged in user so we connect the store to this user
      // ERROR
      try {
        const result = await connectStore(user);
        console.log(result);
        if (shopId !== shop) {
          setShopId(shop);
        }
        history.replace("/b/");
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    console.log("running", user);
    connectLoggedInUser();
  }, [user]);

  const connectStore = async (user: firebase.User) => {
    if (shop && code && hmac && host && timestamp && state) {
      const tokenId = await user.getIdToken();
      try {
        const result = await connectStoreMutation({
          variables: {
            shop,
            code,
            hmac,
            host,
            timestamp,
            state,
          },
          context: {
            headers: {
              authorization: tokenId ? `Bearer ${tokenId}` : "",
            },
          },
        });
        console.log(result);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const createUser = async (data: SignUpInputs): Promise<[string, boolean]> => {
    try {
      await auth
        .createUserWithEmailAndPassword(data.userEmail, data.userPassword)
        .then(async (result) => {
          await result.user.updateProfile({
            displayName: data.userName,
          });
        });
      return [null, true];
    } catch (error) {
      console.error(error);
      return [
        `Error signing up with email and password. ${error.message}`,
        false,
      ];
    }
  };

  const signIn = async (data: SignUpInputs): Promise<[string, boolean]> => {
    try {
      await auth.signInWithEmailAndPassword(data.userEmail, data.userPassword);
      return [null, true];
    } catch (error) {
      console.error(error);
      return ["Error signing in with password and email", false];
    }
  };

  const signInLink = (
    <p className="text-center my-3">
      Already have an account?{" "}
      <button
        onClick={() => {
          setIsSignUpFlow(!isSignUpFlow);
        }}
        className="text-blue-500 hover:text-blue-600"
      >
        Sign in here
      </button>
    </p>
  );

  const signUpLink = (
    <p className="text-center my-3">
      Don't have an account?{" "}
      <button
        onClick={() => {
          setIsSignUpFlow(!isSignUpFlow);
        }}
        className="text-blue-500 hover:text-blue-600"
      >
        Sign up here
      </button>
    </p>
  );

  if (user || localStorage.getItem("authenticated") === "true") {
    // Logged in user
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    return (
      <div className="mt-8">
        <h1 className="text-3xl mb-2 text-center font-bold">
          {isSignUpFlow ? "Sign Up" : "Sign In"}
        </h1>
        {isSignUpFlow ? (
          <SignUpForm submitForm={createUser} />
        ) : (
          <SignInForm submitForm={signIn} />
        )}
        {isSignUpFlow ? signInLink : signUpLink}
      </div>
    );
  }
};

export default ShopifyCallbackPage;
