import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import ApiError from "../components/ApiError";
import Loading from "../components/Loading";
import MediaList from "../components/MediaList";
import { auth } from "../config/firebase";
import { GET_TAGS } from "../graphql/queries";
import useLocationQuery from "../hooks/useLocationQuery";
import { UserContext } from "../providers/UserProvider";

export default function MentionsPage() {
  const { shopId } = useContext(UserContext);
  const query = useLocationQuery();
  const [getTags, { called, error, loading, data }] = useLazyQuery(GET_TAGS, {
    fetchPolicy: "network-only",
  });
  const orderId = query.get("orderId");

  useEffect(() => {
    const loadData = async () => {
      if (shopId) {
        const token = await auth.currentUser.getIdToken();
        getTags({
          variables: {
            shopId,
            orderId,
          },
          context: {
            headers: {
              authorization: token ? `Bearer ${token}` : "",
            },
          },
        });
      }
    };
    loadData();
  }, [shopId]);

  if (!shopId || loading || !called) return <Loading />;
  if (error) return <ApiError error={error} />;

  return (
    <MediaList
      mediaConnection={data.tags}
      getMediaQuery={GET_TAGS}
      title="Tags"
      orderId={orderId}
    />
  );
}
