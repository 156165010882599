import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Spinner from "../Spinner";

export type SignUpInputs = {
  userName: string;
  userEmail: string;
  userPassword: string;
};

interface SignUpFormProps {
  submitForm: (data: SignUpInputs) => Promise<[string | null, boolean]>;
}

export default function SignUpForm({ submitForm }: SignUpFormProps) {
  const [error, setError] = useState<string | null>(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpInputs>();

  const onSubmit: SubmitHandler<SignUpInputs> = async (data) => {
    setIsButtonEnabled(false);
    setIsLoading(true);
    const [errorMessage, success] = await submitForm(data);
    if (success) {
    } else {
      setIsButtonEnabled(true);
      setIsLoading(false);
      setError(errorMessage);
    }
  };

  return (
    <div className="border border-gray-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
      {error !== null && (
        <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="userEmail" className="block">
          Name:{" "}
          {errors.userName && (
            <span className="font-bold text-red-500">
              *This field is required
            </span>
          )}
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full"
          placeholder="E.g: Jane Doe"
          {...register("userName", { required: true })}
        />
        <label htmlFor="userEmail" className="block">
          Email:{" "}
          {errors.userEmail && (
            <span className="font-bold text-red-500">
              *This field is required
            </span>
          )}
        </label>
        <input
          type="email"
          className="my-1 p-1 w-full"
          placeholder="E.g: hello@gmail.com"
          {...register("userEmail", { required: true })}
        />
        <label htmlFor="userPassword" className="block">
          Password:{" "}
          {errors.userPassword && (
            <span className="font-bold text-red-500">
              *This field is required
            </span>
          )}
        </label>
        <input
          type="password"
          className="mt-1 mb-3 p-1 w-full"
          placeholder="Your Password"
          {...register("userPassword", { required: true })}
        />
        <button
          disabled={!isButtonEnabled}
          className="rounded bg-indigo-500 hover:bg-indigo-600 w-full py-2 text-white disabled:opacity-50 disabled:bg-indigo-600"
          type="submit"
        >
          Sign up
        </button>
        {isLoading && (
          <div className="mt-2 flex flex-wrap justify-center content-center">
            <Spinner size="h-6 w-6" />
          </div>
        )}
      </form>
    </div>
  );
}
