import { useMutation } from "@apollo/client";
import { useContext, useState } from "react";
import { auth } from "../config/firebase";
import { SEND_MESSAGE } from "../graphql/mutations";
import { UserContext } from "../providers/UserProvider";
import { timeDifferenceForDate } from "../utils/time";
import Modal from "./Modal";

export default function Messages({
  data,
  username,
  currentUserInstagramId,
  currentUserFacebookId,
}) {
  const [localMessages, setLocalMessages] = useState([]);
  const [response, setResponse] = useState("");
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [storyUrl, setStoryUrl] = useState("");
  const { shopId } = useContext(UserContext);
  const [sendMessageMutation] = useMutation(SEND_MESSAGE);

  const igRecipientScopedId = data.participants.find((participant) => {
    return participant.id !== currentUserInstagramId;
  }).id;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    if (name === "response") {
      setResponse(value);
    }
  };

  const sendMessage = async () => {
    console.log(igRecipientScopedId, currentUserFacebookId, response);

    try {
      const tokenId = await auth.currentUser.getIdToken();
      const result = await sendMessageMutation({
        variables: {
          facebookId: currentUserFacebookId,
          recipientId: igRecipientScopedId,
          text: response,
          shopId,
        },
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
      console.log(result);
      setLocalMessages([...localMessages, { id: "id", text: response }]);
      setResponse("");
      setError(null);
    } catch (err) {
      setError(
        "Error sending message. This message is sent outside of allowed window."
      );
      console.error(err);
    }
  };

  const openImage = (storyUrl) => {
    setStoryUrl(storyUrl);
    setIsModalOpen(true);
  };

  return (
    <div className="flex flex-col w-full">
      {data.messages.map((message) => {
        const showMessageLeft = message.from === username;
        return (
          <div
            key={message.id}
            className={`flex ${showMessageLeft ? "ml-2" : "justify-end"} mt-2`}
          >
            <div
              className={`rounded-full py-3 px-4 ${
                showMessageLeft ? "bg-green-300" : "bg-blue-300"
              }`}
            >
              {message.text ? (
                message.text
              ) : (
                <div>
                  <p>Mentioned in a story.</p>
                  <button
                    className="inline-block align-baseline font-bold text-blue-500 hover:text-blue-800"
                    onClick={() => openImage(message.storyUrl)}
                  >
                    View the story
                  </button>
                </div>
              )}
              <p className="text-right text-xs text-white">
                {timeDifferenceForDate(Number(message.createdAt))}
              </p>
            </div>
          </div>
        );
      })}
      {localMessages.map((message) => {
        return (
          <div key={message.id} className="flex justify-end mt-2">
            <div className="rounded-full py-3 px-4 bg-blue-300">
              {message.text}
              <p className="text-right text-xs text-white">just now</p>
            </div>
          </div>
        );
      })}
      <div className="flex justify-end mt-2">
        {error !== null && (
          <div className="py-3 bg-red-600 w-full text-white text-center mx-1">
            {error}
          </div>
        )}
        <input
          type="text"
          className="w-1/2 rounded mx-2 py-3 px-4 bg-gray-200"
          name="response"
          value={response}
          placeholder="Say something..."
          id="response"
          onChange={(event) => onChangeHandler(event)}
        />
        <button
          className="text-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 border border-blue-500 hover:border-transparent rounded"
          onClick={() => sendMessage()}
        >
          Send
        </button>
      </div>
      {isModalOpen ? (
        <Modal storyUrl={storyUrl} onClickCancel={toggleModal} />
      ) : null}
    </div>
  );
}
