import Spinner from "./Spinner";

const Loading = () => {
  return (
    <div className="h-screen flex flex-wrap justify-center m-10">
      <Spinner size="h-10 w-10" />
    </div>
  );
};

export default Loading;
