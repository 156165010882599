import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ApiError from "../components/ApiError";
import FacebookAccountList from "../components/FacebookAccountList";
import Loading from "../components/Loading";
import Thread from "../components/Thread";
import { auth } from "../config/firebase";
import { GET_STORE_WITH_MESSAGES } from "../graphql/queries";
import { UserContext } from "../providers/UserProvider";

export default function MessagesPage() {
  const { shopId } = useContext(UserContext);
  const [showFacebookPages, setShowFacebookPages] = useState(false);
  const history = useHistory();
  const [getStore, { called, error, loading, data }] = useLazyQuery(
    GET_STORE_WITH_MESSAGES,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    const loadData = async () => {
      if (data) {
        if (data.getShop.hasFacebookAccessToken) {
          if (
            data.getShop.instagramId === null ||
            data.getShop.facebookId === null
          ) {
            setShowFacebookPages(true);
          }
        } else {
          history.push(`/b/connect/facebook?shop=${shopId}`);
        }
      } else {
        console.log("Getting mentions for", shopId);
        if (shopId) {
          const tokenId = await auth.currentUser.getIdToken();
          getStore({
            variables: {
              shopId,
            },
            context: {
              headers: {
                authorization: tokenId ? `Bearer ${tokenId}` : "",
              },
            },
          });
        }
      }
    };
    loadData();
  }, [data, shopId]);

  if (!shopId || loading || !called) return <Loading />;
  if (error) return <ApiError error={error} />;

  if (showFacebookPages) {
    return <FacebookAccountList />;
  }

  return (
    <div className="py-6">
      <Thread
        data={data.getShop.threads}
        currentUserInstagramId={data.getShop.instagramId}
        currentUserFacebookId={data.getShop.facebookId}
      />
    </div>
  );
}
