import { useState, useContext, useEffect } from "react";
import { auth, facebookScopes } from "../config/firebase";
import FacebookLogin from "react-facebook-login";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_FACEBOOK_ACCESS_TOKEN } from "../graphql/mutations";
import { UserContext } from "../providers/UserProvider";
import Loading from "../components/Loading";
import { GET_SETTINGS } from "../graphql/queries";
import FacebookAccountList from "../components/FacebookAccountList";
import ApiError from "../components/ApiError";

const FacebookConnectPage = () => {
  const { shopId } = useContext(UserContext);
  const [updateFacebookAccessToken] = useMutation(UPDATE_FACEBOOK_ACCESS_TOKEN);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isFacebookConnected, setIsFacebookConnected] = useState(false);
  const [isInstagramConnected, setIsInstagramConnected] = useState(false);

  const setSettings = (data) => {
    if (
      data.settings.isInstagramConnected &&
      data.settings.isFacebookConnected
    ) {
      // history.replace("/b/");
    }
    // setIsInstagramConnected(data.settings.isInstagramConnected);
    // setIsFacebookConnected(data.settings.isFacebookConnected);
  };

  const [getSettings, { called, error, loading }] = useLazyQuery(GET_SETTINGS, {
    fetchPolicy: "network-only",
    onCompleted: setSettings,
  });

  useEffect(() => {
    const loadData = async () => {
      if (shopId) {
        const tokenId = await auth.currentUser.getIdToken();
        getSettings({
          variables: {
            shopId,
          },
          context: {
            headers: {
              authorization: tokenId ? `Bearer ${tokenId}` : "",
            },
          },
        });
      }
    };
    loadData();
    return () => {};
  }, [shopId]);

  // This connects to the Facebook account only returning the access token
  const responseFacebook = async (userInfo) => {
    if (userInfo.error) {
      setErrorMessage("Failed to authenticate with Facebook");
      console.error(userInfo.error);
    } else {
      console.log(userInfo);
      try {
        const tokenId = await auth.currentUser.getIdToken();
        const result = await updateFacebookAccessToken({
          variables: {
            accessToken: userInfo.accessToken,
            scopes: Array.from(facebookScopes).join(","),
            shopId,
          },
          context: {
            headers: {
              authorization: tokenId ? `Bearer ${tokenId}` : "",
            },
          },
        });
        console.log(result);
        setIsFacebookConnected(true);
      } catch (err) {
        console.error(err);
      }
    }
  };

  if (!shopId || loading || !called) return <Loading />;
  if (error) return <ApiError error={error} />;

  var content = (
    <div className="flex flex-col justify-center">
      <FacebookLogin
        appId="867624537470691"
        autoLoad={false}
        fields="name,email,picture"
        scope={Array.from(facebookScopes).join(",")}
        callback={responseFacebook}
        containerStyle={{ display: "flex", justifyContent: "center" }}
        textButton="Connect to Facebook"
        cssClass="bg-fb-blue hover:bg-blue-600 py-2 px-4 text-white rounded"
      />
      <div className="mt-2 text-base text-black">
        <p className="mb-2 text-center">
          <span className="">
            In order to connect to your Instagram account it needs to be
            associated with a Facebook Page.
          </span>
          <a
            className="ml-1 text-indigo-600 hover:text-indigo-900"
            href="https://help.instagram.com/399237934150902"
            target="_blank"
          >
            Learn more here.
          </a>
        </p>
        <p className="mb-2 text-center">
          Make sure you have at least the Moderator access on the Facebook page.
        </p>
      </div>
    </div>
  );

  if (isFacebookConnected && !isInstagramConnected) {
    content = <FacebookAccountList />;
  }

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Connect to Facebook
        </h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <div className="flex justify-center p-4 border border-gray-200 rounded-lg">
            {errorMessage ? <div className="mb-4">{errorMessage}</div> : null}
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookConnectPage;
