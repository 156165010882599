import { useMutation } from "@apollo/client";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import { auth } from "../config/firebase";
import {
  ADD_INSTAGRAM_HANDLE_SCRIPT,
  REMOVE_INSTAGRAM_HANDLE_SCRIPT,
} from "../graphql/mutations";

export default function ShopifyScriptInstallStep({
  shopId,
  shopTagline,
  shopIgHandle,
  isScriptInstalled,
}) {
  const [
    isInstagramHandleScriptTagInstalled,
    setIsInstagramHandleScriptTagInstalled,
  ] = useState(isScriptInstalled);
  const [isScriptTagButtonEnabled, setIsScriptTagButtonEnabled] =
    useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisableScriptTagButtonEnabled, setIsDisableScriptTagButtonEnabled] =
    useState(true);

  const [addInstagramHandleScriptMutation] = useMutation(
    ADD_INSTAGRAM_HANDLE_SCRIPT
  );
  const [removeInstagramHandleScriptMutation] = useMutation(
    REMOVE_INSTAGRAM_HANDLE_SCRIPT
  );

  useEffect(() => {
    setIsInstagramHandleScriptTagInstalled(isScriptInstalled);
    return () => {};
  }, [isScriptInstalled]);

  const addInstagramHandleScript = async () => {
    try {
      setIsLoading(true);
      setIsScriptTagButtonEnabled(false);
      const tokenId = await auth.currentUser.getIdToken();
      const result = await addInstagramHandleScriptMutation({
        variables: {
          shopId,
        },
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
      console.log(result);
      setIsInstagramHandleScriptTagInstalled(true);
      setIsScriptTagButtonEnabled(false);
      setIsDisableScriptTagButtonEnabled(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setIsScriptTagButtonEnabled(true);
      console.error(err);
    }
  };

  const attributeCode = `
  <div class="cart-attribute__field" style="font-size: small;display: flex!important;flex-direction: column;align-items: flex-start;/* color: var(--text-color-light); */margin-bottom: 20px;">
    <div for="instagram-handle" style="text-align: left;margin-bottom: 4px;font-size: small;">
      <a id="clado-edu-button" class="clado-edu-button" style="cursor: pointer;">${shopTagline} tag <span style="font-weight: bold;">@${shopIgHandle}. ⓘ</span></a>
    </div>
    <div style="">
      What is your Instagram handle? <input id="instagram-handle" placeholder="@" type="text" name="attributes[instagram-handle]" value="" style="/* margin-left: 3px; */border: 1px solid rgb(124, 124, 124);border-radius: 0.25rem;padding: 1px 5px;">
    </div>
  </div>
  `;

  const removeInstagramHandleScript = async () => {
    try {
      setIsLoading(true);
      setIsDisableScriptTagButtonEnabled(false);
      const tokenId = await auth.currentUser.getIdToken();
      const result = await removeInstagramHandleScriptMutation({
        variables: {
          shopId,
        },
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
      console.log(result);
      setIsInstagramHandleScriptTagInstalled(false);
      setIsScriptTagButtonEnabled(true);
      setIsDisableScriptTagButtonEnabled(false);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setIsDisableScriptTagButtonEnabled(true);
      console.error(err);
    }
  };

  return (
    <>
      <dt>
        <div
          className={`absolute flex items-center justify-center h-8 w-8 rounded-md ${
            isInstagramHandleScriptTagInstalled
              ? "bg-green-400"
              : "bg-green-400"
          } text-white`}
        >
          4
        </div>
        <p className="flex ml-10 text-lg leading-6 font-medium text-gray-900">
          Collect Instagram handles
          <span className="ml-1">
            {isInstagramHandleScriptTagInstalled && (
              <CheckCircleIcon className="text-green-500 mr-4 flex-shrink-0 h-5 w-5" />
            )}
          </span>
        </p>
      </dt>
      <dd className="ml-10 text-base text-gray-500">
        <h3 className="text-sm font-semibold text-gray-800 underline">
          Ask your customer for their Instagram handle during checkout
        </h3>
        <div className="flex mt-1">
          {!isInstagramHandleScriptTagInstalled && (
            <div className="flex flex-col space-y-2">
              <p className="mt-1 text-sm text-gray-600">
                This will add an input field to your cart page to capture
                Instagram handles from your customers.
              </p>
              <div>
                <button
                  type="button"
                  disabled={!isScriptTagButtonEnabled}
                  onClick={() => addInstagramHandleScript()}
                  className="text-center disabled:opacity-50 text-sm bg-transparent hover:bg-indigo-500 text-indigo-700 font-semibold hover:text-white py-2 px-2 border border-indigo-500 hover:border-transparent rounded"
                >
                  Add to store
                </button>
                {isLoading && (
                  <div className="flex flex-wrap ml-4 content-center">
                    <Spinner size="h-6 w-6" />
                  </div>
                )}
              </div>
            </div>
          )}
          {isInstagramHandleScriptTagInstalled && (
            <div className="flex flex-col">
              <p className="mt-1 text-sm text-gray-600">
                <p>
                  <span className="font-bold">Congrats!</span> To see Clado live
                  in your store, add a product to your{" "}
                  <a
                    className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
                    href={`https://${shopId}/cart`}
                  >
                    cart.
                  </a>{" "}
                </p>
                <p className="mt-8">
                  If it is not working, follow the steps below or send us an
                  email to{" "}
                  <a
                    className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
                    href="mailto:support@clado.io"
                  >
                    support@clado.io
                  </a>
                  {". "}
                  We're happy to assist you!
                </p>
              </p>
              <div className="mt-2 text-sm text-gray-600">
                <ol>
                  <li>
                    <span className="font-bold">1.</span> From your Shopify
                    admin, go to Online Store {`> `}
                    <a
                      className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
                      href={`https://${shopId}/admin/themes`}
                      target="_blank"
                    >
                      Themes.
                    </a>
                  </li>
                  <li>
                    <span className="font-bold">2.</span> Find the theme you
                    want to edit, and then click Actions {`>`} Edit code."
                  </li>
                  <li>
                    <span className="font-bold">3.</span> In the Sections
                    directory (under Templates), click cart-template.liquid or
                    main-cart-items.liquid. If your theme doesn't have either,
                    then, in the Templates directory, click cart.liquid.
                  </li>
                  <li>
                    <p>
                      <span className="font-bold">4.</span> Find the closing{" "}
                      <span>
                        <code>{"</form>"}</code>
                      </span>{" "}
                      tag in the code.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="font-bold">5.</span> On a new line right
                      above the closing{" "}
                      <span>
                        <code>{"</form>"}</code>
                      </span>{" "}
                      tag, paste the code from below. You can experiment with
                      putting the code in different places to see where the form
                      field appears on your cart page.
                    </p>
                  </li>
                </ol>
              </div>
              <div className="mt-2">
                <p className="monospace px-2 text-sm whitespace-pre-wrap text-gray-600 border rounded border-gray-500 bg-gray-100">
                  {attributeCode}
                </p>
              </div>
              <div className="mt-2">
                <button
                  type="button"
                  disabled={!isDisableScriptTagButtonEnabled}
                  onClick={() => removeInstagramHandleScript()}
                  className="text-center disabled:opacity-50 text-sm bg-transparent hover:bg-indigo-500 text-indigo-700 font-semibold hover:text-white py-2 px-2 border border-indigo-500 hover:border-transparent rounded"
                >
                  Remove from store
                </button>
                {isLoading && (
                  <div className="flex flex-wrap ml-4 content-center">
                    <Spinner size="h-6 w-6" />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </dd>
    </>
  );
}
