import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { auth } from "../config/firebase";
import { GET_FB_ACCOUNTS } from "../graphql/queries";
import { UserContext } from "../providers/UserProvider";
import FacebookAccount, { FacebookAccountProps } from "./FacebookAccount";
import Loading from "./Loading";
import ApiError from "./ApiError";

const FacebookAccountList = () => {
  const { shopId } = useContext(UserContext);
  const [getFacebookAccounts, { called, error, loading, data }] =
    useLazyQuery(GET_FB_ACCOUNTS);

  useEffect(() => {
    const loadData = async () => {
      if (shopId) {
        const tokenId = await auth.currentUser.getIdToken();
        getFacebookAccounts({
          variables: {
            shopId: shopId,
          },
          context: {
            headers: {
              authorization: tokenId ? `Bearer ${tokenId}` : "",
            },
          },
        });
      }
    };
    loadData();
  }, [shopId, getFacebookAccounts]);

  if (!shopId || loading || !called) return <Loading />;
  if (error) return <ApiError error={error} />;

  console.log(data);

  return (
    <div className="flex flex-col mt-4">
      {data.getFacebookAccounts.map((facebookAccount: FacebookAccountProps) => {
        return (
          <FacebookAccount
            key={facebookAccount.id}
            id={facebookAccount.id}
            name={facebookAccount.name}
            imageUrl={facebookAccount.imageUrl}
          />
        );
      })}
    </div>
  );
};

export default FacebookAccountList;
