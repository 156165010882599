import { useApolloClient } from "@apollo/client";
import { createContext, useEffect, useState } from "react";
import client from "../config/apolloClient";
import { auth } from "../config/firebase";
import { GET_USER } from "../graphql/queries";
import { Shop } from "../interfaces/graphql/Shop";

interface UserRecordInterface {
  id: string;
  email: string;
  name: string;
  pictureUrl: string;
  instagramId: string;
  facebookId: string;
  shops: Array<Shop>;
}

export const UserContext = createContext<{
  user: firebase.default.User | null;
  userRecord: UserRecordInterface;
  shopId: string | null;
  setShopId: React.Dispatch<React.SetStateAction<string>>;
}>(null);

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<firebase.default.User | null>(null);
  const [userRecord, setUserRecord] = useState<UserRecordInterface | null>(
    null
  );
  const [shopId, setStateShopId] = useState<string | null>(null);
  const apolloClient = useApolloClient();

  const setShopId = (newShopId: string) => {
    if (newShopId) {
      localStorage.setItem("shopId", newShopId);
      setStateShopId(newShopId);
    }
  };

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async (userAuth) => {
      // TODO: Get more data if we need it about the user. This happens when we
      // find the logged in user.

      // Reset the store so previously logged in user doesn't get the old queries
      client.resetStore();

      if (userAuth) {
        localStorage.setItem("authenticated", "true");
        const tokenId = await userAuth.getIdToken();
        try {
          const result = await apolloClient.query({
            query: GET_USER,
            fetchPolicy: "network-only",
            context: {
              headers: {
                authorization: tokenId ? `Bearer ${tokenId}` : "",
              },
            },
          });

          // If there is no store set then we pick the first store, otherwise use the set one
          const localShopId = localStorage.getItem("shopId");
          if (localShopId) {
            setShopId(localShopId);
          } else {
            setShopId(
              result.data.getUser.shops.length > 0
                ? result.data.getUser.shops[0].id
                : null
            );
          }

          setUserRecord(result.data.getUser);
        } catch (error) {
          console.error(error);
        }
      } else {
        localStorage.setItem("authenticated", "false");
        localStorage.removeItem("shopId");
      }
      setUser(userAuth);
    });
    return () => {
      client.resetStore();
      localStorage.removeItem("shopId");
      localStorage.setItem("authenticated", "false");
      unsub();
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, userRecord, shopId, setShopId }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
