import Customer from "../interfaces/graphql/Customer";
import { numberWithCommas } from "../utils/formattingNumbers";

interface CustomerRowInterface {
  customer: Customer;
}

export default function CustomerRow({ customer }: CustomerRowInterface) {
  console.log(customer.instagramUserData?.username, customer.igUsername);
  const igUsername = customer.instagramUserData?.username
    ? customer.instagramUserData?.username
    : customer.igUsername;
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <a
          className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
          href={`https://www.instagram.com/${igUsername}`}
          target="_blank"
          rel="noreferrer"
        >
          @{igUsername}
        </a>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {customer.firstName} {customer.lastName}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">{customer.email}</td>
      <td className="px-6 py-4 whitespace-nowrap">{customer.phoneNumber}</td>
      <td className="px-6 py-4 whitespace-nowrap">
        {customer.instagramUserData?.followers &&
          numberWithCommas(customer.instagramUserData?.followers)}
      </td>
    </tr>
  );
}
