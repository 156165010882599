import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Messages from "./Messages";

export default function Thread({
  data,
  currentUserInstagramId,
  currentUserFacebookId,
}) {
  const [thread, setThread] = useState(null);
  const params = useParams();

  // <Image source={message.storyUrl} fallbackUrl="/images/expired.jpg" />

  useEffect(() => {
    if (params["id"]) {
      const newThread = data.find((thread) => {
        const selectedThread = thread.participants.find((participant) => {
          return participant.username === params["id"];
        });

        return selectedThread !== undefined;
      });
      if (newThread) {
        setThread(newThread);
      }
    } else {
      setThread(null);
    }
    return () => {};
  }, [params]);

  return (
    <div className="flex divide-x-4 divide-green-600 w-full">
      <div className="flex flex-col m-2">
        {data.map((thread) => {
          return thread.participants.map((participant) => {
            if (participant.id === currentUserInstagramId) {
              return null;
            } else {
              return (
                <div className="max-h-11 p-3 w-full">
                  <Link
                    key={thread.id}
                    className="p-2 w-full text-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white border border-blue-500 hover:border-transparent rounded"
                    to={`/b/messages/${participant.username}`}
                  >
                    {participant.username}
                  </Link>
                </div>
              );
            }
          });
        })}
        {/* <a
          className="max-h-11 text-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 border border-blue-500 hover:border-transparent rounded"
          href={`/${data.participants[0].username}`}
        >
          {data.participants[0].username}
        </a> */}
      </div>
      {thread ? (
        <Messages
          data={thread}
          username={params["id"]}
          currentUserInstagramId={currentUserInstagramId}
          currentUserFacebookId={currentUserFacebookId}
        />
      ) : (
        <div>{`<---- Pick a conversation on the left `}</div>
      )}
    </div>
  );
}
